<template>
  <div class='container'>
    <h5>
<!--        <img src="@assets/img/profile/icon-back.png"/>-->
<!--      传输记录-->
    </h5>


    <div class="content">
      <div class="view-type">
        <div :class=" viewType == 1? 'view-type-item view-type-item-active':'view-type-item'" @click=" handelViewType(1) ">
          <img style="width: 16px;height: 16px" :src="viewType == 1 ? send1:send2" alt="">
          发件记录
        </div>
        <div :class=" viewType == 2? 'view-type-item view-type-item-active':'view-type-item'" @click=" handelViewType(2) " >
          <img style="width: 18px;height: 14px" :src="viewType == 2 ? pickup1:pickup2" alt="">
          取件记录
        </div>
        <div  :class=" viewType == 1 ?'line _setFile':'line _getFile'"></div>
      </div>
    <!--    发件记录  -->
      <div class="record-Box" v-if="viewType == 1">
        <div class="record-header">
          <div class="record-filter" v-if="!isClickSelect">
            <!-- 筛选 -->
            <div class="filter-type">
              <div class="filter-type_val" @click.stop="showFilter=!showFilter">
                筛选：{{filterVal.type}}
                <img src="@assets/img/index/icon-drop.png"/>
              </div>
              <transition name="el-zoom-in-top">
                <div class="select-dropdown_wrap" v-show="showFilter" v-clickoutside="handleFilterClose">
                  <ul>
                    <li v-for="item in filterList" :key="item.id" @click="handleClickFilter(item.id,item.type,item.filter)">
                      <span>{{item.type}}</span>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <!-- 排序 -->
            <div class="sort-type">
              <div class="filter-type_val" @click.stop="showSort=!showSort">
                排序：{{sortVal.type}}
                <img src="@assets/img/index/icon-drop.png"/>
              </div>
              <transition name="el-zoom-in-top">
                <div class="select-dropdown_wrap" v-show="showSort" v-clickoutside="handleSortClose">
                  <ul>
                    <li v-for="item in sortList" :key="item.id" @click="handleClickSort(item.id,item.type,item.orderBy)">
                      <span>{{item.type}}</span>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <!-- 选择 -->
            <div class="select-btn" @click="isClickSelect=!isClickSelect">
              <img src="@assets/img/profile/icon-selected2.png"/>
              <span>选择</span>
            </div>
          </div>
          <!-- 操作 -->
          <div class="record-actions" v-else>
            <img :src="isCheckAll?selected:unselected" @click="handleClickAll" class="icon-checkbox"/>
            <div :class="checkLength?'selected btn1':'btn1'" @click="deleteCancelSelect">
              <i class="iconfont icon-shanchu2"></i>删除记录
            </div>
            <div class="btn2" @click="handleCancelSelect">
              取消选择
            </div>
          </div>

  <!--        <div class="space-used">-->
  <!--          <img src="@assets/img/profile/icon-space2.png"/>-->
  <!--          <span>96.12%已使用</span>-->
  <!--        </div>-->
        </div>
        <div class="record-card" @scroll="scrollEvent($event,1)"  >
          <div v-if="recordList.length !=0" class="record-list">
            <div class="record-item" v-for="(item,index) in recordList" :key="index">
              <div class="record-item-content" @click="openDropdown(item)">
                <div class="record-item-content__header">
                  <div class="record-item-content__header-left">
                    <img :src="item.isSelect?selected:unselected" v-show="isClickSelect" @click.stop="item.isSelect=!item.isSelect" style="margin-right:8px;"/>
                    <img src="@assets/img/index/icon-send_file.png"/>
                    <span>我发送的（{{item.icon_name}}）等{{item.files_list.length}}个文件</span>
                    <img src="@assets/img/profile/icon-open_search.png"/>
                  </div>
                  <div class="record-item-content__header-right">
                    <i class="iconfont icon-link"
                       v-clipboard:copy="item.url"
                       v-clipboard:success="copySuccess"
                       v-clipboard:error="copyError"
                    ></i>
<!--                    item.isShowMore = !item.isShowMore-->
                    <i class="iconfont icon-iosgengduo" @click.stop="handelIsShowMore(item,index)"></i>
                    <transition name="el-zoom-in-top">
                      <div class="select-dropdown_wrap"  style="margin: 0" v-show="item.isShowMore">
                        <ul>
                          <li v-for="(item2,key) in moreList" :key="item2.id" @click.stop="again(item2.id,key,index,item)">
                            <span>{{item2.type}}</span>
                          </li>
                        </ul>
                      </div>
                    </transition>
                <!--    重新上传   -->
                    <transition name="el-fade-in">
                      <div class="select-dropdown_wrap again" v-show="item.isShowAgain">
                        <ul>
                          <li  @click.stop="getrandom();share_id = item.id;fileList = []" >
                            <img src="@assets/img/index/icon-file.png"/>
                            <span>单个/多个文件</span>
                            <input type="file"   @change="uploadFile($event,'file')" multiple  >
                          </li>
                          <li @click.stop="getrandom();share_id = item.id;fileList = []">
                            <img src="@assets/img/index/icon-folder.png"/>
                            <span>整个文件夹</span>
                            <input @change="uploadFile($event,'files')" type="file" webkitdirectory>
                            <!--              文件上传-->
                          </li>
                          <li @click.stop="share_id = item.id;opencloudDiskVisible('',1);fileList = []">
                            <img src="@assets/img/index/icon-cloud.png"/>
                            <span>从我的云盘选择</span>
                          </li>
                        </ul>
                        <div class="arrow"></div>
                      </div>
                    </transition>

                    <!--   链接设置 -->
                    <transition name="el-fade-in">
                      <div class="select-dropdown_wrap setUpLink" v-show="item.isShowSetUpLink">
                        <!-- 搜索权限 -->
                        <div class="setting-item" >
                          <div class="setting-item-left">
                            搜索权限
                            <img :src="setUpLinkObj.s1?helpImg1:helpImg0" @mouseenter="setUpLinkObj.s1=true" @mouseleave="setUpLinkObj.s1=false"/>
                            <div class="tooltip s1" v-show="setUpLinkObj.s1  ">
                              <div class="cone"></div>
                              <p>
                                开启后文件可被其他用户搜索或下载，若是私密文件，请谨慎开启！
                              </p>
                            </div>
                          </div>
                          <div class="setting-item-right">
                            <div :class="setUpLinkObj.searchPermission?'is-checked switch':'switch'" @click.stop="setUpLinkObj.searchPermission = !setUpLinkObj.searchPermission">
                              <span></span>
                            </div>
                          </div>
                        </div>
                        <!-- 定价 -->
                        <div class="setting-item">
                          <div class="setting-item-left">
                            文件定价
                            <img :src="setUpLinkObj.s2?helpImg1:helpImg0" @mouseenter="setUpLinkObj.s2 = true" @mouseleave="setUpLinkObj.s2 = false"/>
                            <div class="tooltip s2" v-show="setUpLinkObj.s2">
                              <div class="cone"></div>
                              <p>
                                设定价格后，其他用户下载该文件需付费，收益在“我的钱包”中可提现！
                              </p>
                            </div>
                          </div>
                          <div class="setting-item-right" style="position: relative">
                            <span v-if="setUpLinkObj.showPrice1" @click.stop="handelPrice">{{setUpLinkObj.price == 0?'免费':setUpLinkObj.price + '元'}}</span>
                            <div v-else>
                              <input
                                  maxlength="9"
                                  style="width: 70px;text-align: right"
                                  placeholder=""
                                  @blur="setUpLinkObj.showPrice1 = true"
                                  v-model="setUpLinkObj.price"
                                  type="text"
                                  @input="limitInput"
                              >
                              元
                            </div>
                            <div class="pricingBox" v-show="setUpLinkObj.showPrice" @mouseout="setUpLinkObj.showPrice2 = false" @mousemove="setUpLinkObj.showPrice2 = true" >
                              <div class="send_file-popver1"  >
                                <!--                <div class="cone"></div>-->
                                <div class="send_file-popver-title">开通VIP会员</div>
                                <ul>
                                  <li>
                                    <img src="@assets/img/index/icon-user.png" alt="">
                                    解锁文件定价特权
                                  </li>
                                  <li>
                                    <img src="@assets/img/index/icon-user.png" alt="">
                                    专属文件传输背景
                                  </li>
                                  <li>
                                    <img src="@assets/img/index/icon-user.png" alt="">
                                    尊享空间、流量、折扣
                                  </li>
                                </ul>
                                <router-link to="/shop">
                                  <div class="send_file-popver-btn">
                                    立即开通
                                  </div>
                                </router-link>
                              </div>

                            </div>
                          </div>
                        </div>
                        <!-- 提供流量  -->
                        <div class="setting-item" >
                          <div class="setting-item-left">
                            提供流量
                            <img :src="setUpLinkObj.s3?helpImg1:helpImg0" @mouseenter="setUpLinkObj.s3=true" @mouseleave="setUpLinkObj.s3=false"/>
                            <span v-show="uploadBefore.visitor_upload == 0&& setUpLinkObj.isProvideFlow">
                              {{ formatSize(  setUpLinkObj.free_flow_total + setUpLinkObj.flowTotal * setUpLinkObj.flow ) }}
                            </span>
                            <div class="tooltip s3" v-show="setUpLinkObj.s3">
                              <div class="cone"></div>
                              <p>
                                对方下载该文件，消耗您提供的流量
                              </p>
                            </div>
                          </div>
                          <div class="setting-item-right">
                            <div class="flow" v-if="setUpLinkObj.isProvideFlow">
                              <img src="@assets/img/index/icon-reduce.png" @click.stop="reduce"/>
                              <input type="text" v-model="setUpLinkObj.flow"  @input="handelProvideFlow" style="width:40px;text-align: right;text-align: center;margin:0 10px;border: 1px solid #ccc;">
                              <img src="@assets/img/index/icon-add.png" @click.stop="upReduce"/>
                              <span style="margin-left: 10px;">次</span>
                            </div>
                            <!--           -->
                            <div :class="setUpLinkObj.isProvideFlow?'is-checked switch':'switch'" @click.stop="handelProvideFlow" v-else>
                              <span></span>
                            </div>
                          </div>
                        </div>
                        <!-- 允许他人  -->
                        <div class="setting-item">
                          <div class="setting-item-left">
                            允许他人
                          </div>
                          <div class="setting-item-right" @click.stop="setUpLinkObj.providePermissionVisible=!setUpLinkObj.providePermissionVisible">
                            <i>{{setUpLinkObj.providePermission.val}}</i>
                            <img src="@assets/img/index/icon-arrow_right.png" class="arrow1" :style="setUpLinkObj.providePermissionVisible?'transform:rotate(90deg)':''"/>
                            <transition name="el-zoom-in-top">
                              <div class="dropdown" v-show="setUpLinkObj.providePermissionVisible">
                                <div class="cone"></div>
                                <ul>
                                  <li @click.stop="choiceDropdown(1,'下载、转存及预览')" >下载、转存及预览</li>
                                  <li @click.stop="choiceDropdown(2,'仅下载或转存')"  >仅下载或转存</li>
                                  <li @click.stop="choiceDropdown(3,'仅预览')" >仅预览</li>
                                </ul>
                              </div>
                            </transition>
                          </div>
                        </div>
                        <!-- 有效期 -->
                        <div class="setting-item">
                          <div class="setting-item-left">
                            有效期
                          </div>
                          <div class="setting-item-right" @click.stop="setUpLinkObj.validPeriodVisible=!setUpLinkObj.validPeriodVisible">
                            <i>{{setUpLinkObj.validPeriod.val | validPeriodType}}</i>
                            <img src="@assets/img/index/icon-arrow_right.png" class="arrow1"
                                 :style="setUpLinkObj.validPeriodVisible?'transform:rotate(90deg)':''"/>
                            <transition name="el-zoom-in-top">
                              <div class="dropdown" v-show="setUpLinkObj.validPeriodVisible">
                                <div class="cone"></div>
                                <ul>
                                  <li v-for="(item) in uploadBefore.expiry_day_list" :key="item" @click.stop="choiceTimeDropdown(item,item)"  >{{ item | validPeriodType }}</li>
                                </ul>
                              </div>
                            </transition>
                          </div>
                        </div>
                        <!-- 补充描述  -->
                        <div class="setting-item"  style="display:block">
                          <div class="setting-item-left"  >
                            补充描述
                          </div>
                          <div class="setting-item-right" style="margin-top:10px">
                            <textarea v-model.trim="setUpLinkObj.supplement" placeholder="请输入描述内容，它将展示给收到的用户。" autocomplete="off"></textarea>
                          </div>
                        </div>
                        <div class="setting-btnBox">
                          <div class="btn1" @click.stop="submitSetUpLink(item)">确认修改</div>
                          <div class="btn2">
                            <div class="btn2sty" @click.stop="item.isShowSetUpLink = false">取消</div>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>

                  <div class="expiry-time"  v-if="item.expiry_time !='已过期'">
                    <i>{{item.expiry_time == '永久有效' ?item.expiry_time:item.expiry_time+'后到期' }}</i>
                    <img src="@assets/img/index/icon-drop.png" @click.stop="item.isShowDate = !item.isShowDate"/>
                    <transition name="el-zoom-in-top">
                      <div class="expiry-time-list" v-show="item.isShowDate" v-clickoutside="handleCloseExpireTime">
                        <ul>
                          <li v-for="item2 in expireTimes" :key="item2.id" @click.stop="handleClickExpireTime(item.id,item2.id)">
                            <span>{{item2.type}}</span>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                  <div v-else class="expiry-time" >
                    <i style="color: #E30000">!已过期销毁</i>
                  </div>
                </div>
                <div class="record-item-content__info">
                  <span class="splits">下载{{item.download_num}}</span>
                  <span class="splits">浏览{{item.browse_num}}</span>
                  <span class="splits">转存{{item.save_to_num}}</span>
                  <span class="splits">{{formatSize(item.size_total )}}</span>
                  <span class="splits">￥{{item.price?item.price:0}}</span>
                  <span class="splits">上传于{{item.create_time}}</span>
                  <el-popover
                    v-show="item.remark"
                    placement="right"
                    width="200"
                    trigger="hover"
                    :content="item.remark">
                    <span slot="reference" class="desc">补充描述</span>
                  </el-popover>
                </div>
              </div>
              <div class="record-item-table" v-show="item.isShowList">
                <div class="record-item-tr" v-for="(data,t) in item.files_list" :key="data.id">
                  <div class="nameBox" v-if="data.name!=''">
                      <div class="name">{{data.name}}</div>
                      <div>{{ data.size != '-'? formatSize( data.size ) :'-'}}</div>
                  </div>
                  <div v-else class="">
                    暂无文件
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="record-placeholder">
            <img src="@assets/img/profile/icon-empty.png"/>
            <p>暂无记录</p>
          </div>
        </div>
      </div>
    <!--    取件记录   -->
      <div class="record-Box" v-if="viewType == 2">
        <div class="record-card1" style="height: calc(100% - 14px)"  @scroll="scrollEvent($event,2)" >
          <div v-if="getRecordsList.length != 0" class="record-list">
            <div class="record-item" v-for="(item,index) in getRecordsList" :key="index">
              <div class="record-item-content" >
                <div class="record-item-content__header">
                  <div class="record-item-content__header-left" @click.stop="item.isSelect=!item.isSelect">
<!--                    <img :src="item.isSelect?selected:unselected" v-show="isClickSelect" @click.stop="item.isSelect=!item.isSelect" style="margin-right:8px;"/>-->
                    <img src="@assets/img/profile/icon-pickup1.png"/>
                    <!-- <img src="@assets/img/index/icon-send_file.png"/> -->
                   <span>我下载的（{{item.name}}）{{ item.number }}</span>
                    <img  style="position: relative;" src="@assets/img/profile/filesearch-outlined.png" class="icon" />
                  </div>

                </div>
                <div class="record-item-content__info">
<!--                  <span class="splits">大小2.65MB</span>-->
                  <span class="splits">消费流量{{item.flow? formatSize(item.flow) : '-'}}</span>
                  <span class="splits">消费金额{{item.price?'￥'+item.price : '  -'}}</span>
                  <span class="splits" style="position: inherit">取件于{{item.create_time}}</span>
                </div>
              </div>
              <div class="record-item-table" v-show="item.isSelect">
                <div class="record-item-tr">
                  <span>该文件您已经下载到本地设备，可到自己设置的浏览器下载路径中查看，云端只记录了您的下载记录，无法查看已下载到本地的文件。</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="record-placeholder">
            <img src="@assets/img/profile/icon-empty.png"/>
            <p>暂无记录</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 销毁已选任务弹窗 -->
    <div class="overlay" v-show="showDeleteTransfer">
      <div class="wrap">
        <div class="destory-dialog">
          <img src="@assets/img/index/icon-close2.png" @click="showDeleteTransfer = false" class="close"/>
          <div class="dialog-header">
            <span>销毁已选任务</span>
          </div>
          <div class="dialog-body">
            <p>确定立即销毁已选的1个任务吗？<br/>注意：对方无法继续查看、下载文件</p>
          </div>
          <div class="dialog-footer">
            <div class="btn confirm-btn" @click="handeldeleteShare(1)" >是</div>
            <div class="btn cancel-btn" @click="showDeleteTransfer=false">
              <span>否</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 删除记录弹窗 -->
    <div class="overlay" v-show="showDeleteRecord">
      <div class="wrap">
        <div class="delete-dialog">
          <img src="@assets/img/index/icon-close2.png" @click="showDeleteRecord = false" class="close"/>
          <div class="dialog-header">
            <span>确定要删除记录吗</span>
          </div>
          <div class="dialog-body">
            <p>确定立即销毁已选的{{checkLength}}个记录吗？</p>
          </div>
          <div class="dialog-footer">
            <div class="btn confirm-btn" @click="handeldeleteShare(2)"  >是</div>
            <div class="btn cancel-btn" @click="showDeleteRecord=false">
              <span>否</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 文件传输 -->
    <div class="overlay" v-show="showFileTransfer">
      <div class="wrap">
        <div class="file-dialog">
          <img src="@assets/img/index/icon-send.png" v-show="yunpanObj.type != 1"  @click="showSendFile2 = !showSendFile2" class="close"/>
          <div class="dialog-header">
            <span>文件传输</span>
            <span>共{{fileAllSize? formatSize(fileAllSize ):formatSize(fileSize / 1000)}}</span>
          </div>
          <div class="dialog-body">
            <div class="file-list">
              <div class="file-item" v-for="(item,index) in fileList" :key="index">
                <div class="file-item-left">
                  <div class="file-name">
                    <img :src="getImgType(item.suffix)">
                    <p>{{item.name}}</p>
                  </div>
                  <div class="file-size">{{ item.size !=0 ?formatSize(item.size ):'-'}}</div>
                </div>
                <img src="@assets/img/file/icon-close2.png"  @click="deleteFile(index)" class="delete"/>
              </div>
            </div>
            <div class="agree">
              <img src="@assets/img/index/icon-agreed2.png" v-if="isAgree" @click="isAgree=false"/>
              <span v-else class="isAgree" @click="isAgree=true"></span>
              <div class="fontBox">
                <span>我已阅读且同意</span>
                <span class="routerBox" v-for="(item,index) in homeInfo.upload_protocol_list" :key="index">
                      <span @click="toAgree(item)" class="router">《{{item.title}}》</span>
                      <span v-if="homeInfo.upload_protocol_list.length -1 !=  index">和</span>
                    </span>
                <span>并对上传的文件的合法性负全部责任。</span>
              </div>
            </div>
          </div>
          <div class="dialog-footer">
            <div class="btn" @click="submitUpload">开始上传</div>


          </div>

          <!-- 发文件菜单 -->
          <transition name="el-fade-in">
            <!--          v-clickoutside="handleClose4"-->
            <div class="send_file-popver" v-show="showSendFile2"  >
              <div class="cone"></div>
              <ul>
                <li @click="getrandom">
                  <img src="@assets/img/index/icon-file.png"/>
                  <span>单个/多个文件</span>
                  <input type="file"   @change="uploadFile($event,'file')" multiple  >
                </li>
                <li @click="getrandom" >
                  <img src="@assets/img/index/icon-folder.png"/>
                  <span>整个文件夹</span>
                  <input type="file"   @change="uploadFile($event,'files')"  webkitdirectory  >
                </li>


                <li  v-show="yunpanObj.type != 2">
                  <img src="@assets/img/index/icon-cloud.png"/>
                  <span>从我的云盘选择</span>
                </li>
              </ul>

            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- 文件传输中... -->
    <div class="overlay" v-show="showTransfer">
      <div class="wrap">
        <div class="transfer-dialog">
          <div v-if="percentage == 100" class="transfer_titleImgBox" >
            <img src="@assets/img/index/upload_ok.png" alt="">
          </div>
          <CircleLoading v-else  :percentage="1"/>
          <span>{{this.speed}}</span>
          <p>{{fileList.length}}个文件传输中</p>
        </div>
      </div>
    </div>

    <!-- 我的云盘 -->
    <div class="cloudDiskBox" v-if="cloudDiskVisible">
      <div class="cloudDisk" >
      <img src="@assets/img/index/icon-close.png" class="close" @click="cloudDiskVisible=false"/>
      <div class="cloudDisk-header">
        <h5>我的云盘</h5>
        <div class="title">
          <h6 v-for="(item,index) in yunpanTitle" :key="index" @click="yunpanIndex = 1;yunpanState = false;opencloudDiskVisible(item,1,index)">
            <img src="@assets/img/index/icon-files.png"/>
            <span>{{item.title}}</span>
            <div style="margin: 0 5px" v-show="yunpanTitle.length - 1 != index"> > </div>
          </h6>
        </div>
      </div>
      <div class="files">
        <!-- 全选 -->
        <div class="all-file">
          <div class="file-left">
            <span class="checkbox_input" @click="handleyunpanClickAll();yunpanAllCheck = !yunpanAllCheck">
              <img :src="yunpanAllCheck?checkboxImg1:checkboxImg0"/>
              <input type="checkbox" :checked="yunpanAllCheck" aria-hidden="false"/>
            </span>
            <i>名称</i>
          </div>
          <div class="file-right">大小</div>
        </div>
        <!-- 文件列表 -->
        <div class="file-list" @scroll="getYunpanPage">
          <div class="file-item" v-for="(item,index) in yunpanfileList" :key="index" >
            <div class="file-item-left">
              <span class="checkbox_input" :style="item.isCheck?'visibility:visible':''">
<!--                 pushIsCheck(item);-->
                <img  @click="item.isCheck=!item.isCheck"  :src="item.isCheck?checkboxImg1:checkboxImg0"/>
                <input type="checkbox" :checked="item.isCheck" aria-hidden="false"/>
              </span>
              <img  src="@assets/img/index/icon-file.png" class="icon-file"/>


              <p  @click="yunpanIndex = 1;opencloudDiskVisible(item,item.type,index);" >{{ item.name }}</p>
            </div>
            <div class="file-item-right">
              {{item.size?formatSize(item.size):'-'}}
            </div>
          </div>
        </div>
      </div>
      <div class="tools">
        <p>已选{{yunpancheckLength}}项，{{formatSize(checkSize)}}</p>
        <div  class="cancel">
          <span @click="cloudDiskVisible = false">取消</span>
        </div>
        <div class="confirm" @click="submityunpanData">确定</div>
      </div>
    </div>
    </div>
    <!-- 继续打包 -->
    <div class="downloadPupBox" v-if="showContinueDownLoadTips">
      <div class="downloadPup">
        <img class="del"  @click="showContinueDownLoadTips = false" src="@assets/img/index/icon-close2.png" alt="">
        <div class="title">继续打包提示</div>
        <div class="fontBox">
          <div class="font">
            <span>打包这批文件大约需要{{packZipTime}}分钟，建议选择单个文件下载!</span>
          </div>
        </div>
        <div  class="btnBox">
          <div class="btn1" @click="handelerDownload();continueDownLoadTipsState = false;showContinueDownLoadTips = false ">继续打包</div>
          <div class="btn2" @click="showContinueDownLoadTips = false;continueDownLoadTipsState = true">取消</div>
        </div>

      </div>


    </div>
    <!-- 下载预览流量消耗弹窗样式 -->
    <div class="downloadPupBox" v-if="showTooltips">
      <div class="downloadPup">
        <img class="del"   @click="showTooltips = false" src="@assets/img/index/icon-close2.png" alt="">
        <div class="title">流量消耗提示</div>
        <!--      下载-->
        <div class="fontBox" >
          <span>文件{{formatSize(fileAllSize )}}；</span>
          <span>在线下载需{{ formatSize(fileAllSize ) }}流量</span>
          <span>(您还剩{{formatSize(userInfo.flow_over_total )}})</span>
          <span v-show="!f3">，是否确认下载？</span>
        </div>
        <!--      下载-->
        <div v-if="f1" class="btnBox">
          <div class="btn1" @click="handelfiledownload" >确认下载</div>
          <div class="btn2" @click="showTooltips = false;continueDownLoadTipsState = true" >取消</div>
        </div>
        <!--    流量不足 -->
        <div v-if="f3" class="btnBox" @click="toShop" >
          <div class="btn3">
            当前账户流量余额不足，前往充值
            <img src="@assets/img/index/icons-next.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleLoading from '@components/CircleLoading.vue'
import {
  deleteShare,
  editExpiryTime, editShare, getExpensesRecordList, getShareInfo, myShareDownload,
  reUploadShareByDisk,
  reUploadShareByFiles,
  shareRecord
} from "@assets/api/transferRecord";
import {getHomeInfo, getPanList, getSizeTotal, inquireTask, uploadBefore} from "@assets/api/home/home";
import {mapState} from "vuex";
import axios from "axios";
import {baseUrl} from "../../../../config";
const clickoutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.vueClickOutside = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  update() {},
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.vueClickOutside);
    delete el.vueClickOutside;
  },
};
export default {
  name:'',
  components: {
    CircleLoading
  },

  directives:{
    clickoutside
  },

  data () {
    return {
      selected:require('../../../assets/img/profile/icon-selected.png'),
      unselected:require('../../../assets/img/profile/icon-unselected.png'),
      checkboxImg0:require('@assets/img/index/icon-select0.png'),
      checkboxImg1:require('@assets/img/index/icon-select1.png'),
      showSendFile2:false,//弹窗上传
      isClickSelect:false, //是否点击选择
      send1:require('@assets/img/profile/icon-send1.png'),
      send2:require('@assets/img/profile/icon-send2.png'),
      pickup1:require('@assets/img/profile/icon-pickup1.png'),
      pickup2:require('@assets/img/profile/icon-pickup2.png'),
      helpImg0:require('@assets/img/index/icon-transfer_help0.png'),  //文件传输帮助图标
      helpImg1:require('@assets/img/index/icon-transfer_help1.png'),  //文件传输帮助图标
      viewType:1,//navBar
      //打包解压
      showZipList:[],
      showZipListType : 0,
      packZipList:[],//下载数组

      cloudDiskVisible:false,
      yunpanList:[],//从我的云盘上传文件
      yunpanTitle:[],//云盘头部
      yunpanIndex:1,//分页下标
      yunpanAllCheck:false,//云盘全选
      yunpanArr:[],//临时数组
      yunpanObj:{
        type:1, //1 为 云盘上传 2为文件上传
        files:[],
      },
      yunpanfileList:[],//云盘数据
      //筛选
      showFilter:false,
      filterVal:{
        id:'1',
        type:'显示全部',
        filter:'',
      },
      pageIndex:1,//分页
      recordObj:{},//数据
      sendList:[],//发件数组
      random:'',
      filterList:[
        {id:1,type:'显示全部',filter:'1'},
        {id:2,type:'未过期',filter:'2'},
        {id:3,type:'即将过期',filter:'3'},
        {id:4,type:'已过期销毁',filter:'4'}
      ],
      //排序
      showSort:false,
      sortVal:{
        id:'1',
        type:'上传时间',
        orderBy:'create_time'
      },
      sortList:[
        {id:1,type:'上传时间',orderBy:'create_time'},
        {id:2,type:'过期时间',orderBy:'expiry_time'},
        {id:3,type:'占用空间大小',orderBy:'size'}
      ],
      share_id:'',//分享链接主键id
      // 发件记录列表
      recordList:[],
      //取件记录
      getRecordsList:[],
      //更多操作
      moreList:[
        {id:4,type: '链接设置'},
        // {id:1,type:'重新上传'},
        {id:2,type:'下载全部'},
        {id:3,type:'销毁链接'},
      ],


      //到期时间
      showExpireTimeList:false, //到期时间列表显示
      expireTimes:[
        {id:1,type:'1天'},
        {id:7,type:'7天'},
        {id:30,type:'30天'},
        {id:0,type:'永久有效'},
      ],

      showDeleteTransfer:false, //销毁已选任务弹窗
      showDeleteRecord:false, //删除记录弹窗

      showFileTransfer:false,
      isAgree:false, //上传是否同意

      showTransfer:false,
      percentage:0,
      timer:null,

      fileList:[],//文件数组
      time:'',//当前时间
      uploadBefore:{},//上传参数
      sensitiveKeywordList:[],//敏感字
      uploadSize:0,
      uploadLoaded:0,
      speed:'',
      fileInfo:{},//文件信息
      homeInfo:{},
      shardSize:'',//初始化单次上传的大小
      t0:new Date(),
      yunpanState:false,
      //链接设置操作
      setUpLinkObj:{
        s1:false, //搜索权限提示
        s2:false,//定价
        s3:false,//提供流量
        searchPermission:false,//搜索权限
        price:'',//定价价格
        showPrice:false,//会员定价弹窗
        showPrice1:true,//会员定价 输入框 和免费切换
        showPrice2:true,//会员定价弹窗 自动消失
        isProvideFlow:false, //是否自定义提供流量
        providePermissionVisible:false, //允许他人弹窗
        validPeriodVisible:false, //有效期弹窗
        validPeriod:{},//有效期
        validPeriodList:[],//有效期数组
        providePermission:{id:1,val:'下载、转存及预览'}, //允许他人
        flow:1,//流量倍数
        flowTotal:0,//总流量
        supplement:'',//描述
      },
      pagingState:false,//分页状态
      fileAllSize:0,//云盘选择文件大小
      fileSizeTotalInfo:{},//数据
      isZip:false,//是否需要打包弹窗
      packZipTime:1,//打包时间
      continueDownLoadTipsState:true,//打包状态
      showContinueDownLoadTips:false,//打包窗口
      showTooltips:false,//消耗流量提示
      f1:true,
      f2:false,
      f3:false,
      downloadList:[],
    };
  },
  filters:{
    //过滤有效期
    validPeriodType(val){
      if(val == 0) return '永久有效'
      else{
        return  val + '天'
      }
    },
  },
  computed: {
    ...mapState({
      userInfo:'userInfo',//用户信息
      closePopup:'closePopup',
    }),
    //选中的数量
    checkLength(){
      return this.recordList.filter(item=>{
        return item.isSelect
      }).length
    },
    //判断全选
    isCheckAll(){
      if(!this.recordList.length){
        return false;
      }
      return this.recordList.length===this.checkLength;
    },
    //云盘选中的数量
    yunpancheckLength(){
      return this.yunpanfileList.filter(item=>{
        return item.isCheck
      }).length
    },
    isyunpanCheckAll(){
      if(!this.yunpanfileList.length){
        return false;
      }
      return this.yunpanfileList.length===this.yunpancheckLength;
    },
    //选择文件的大小
    checkSize(){
      let size = 0
      //切换页时也保留选择
      // this.yunpanList.filter(item=>{
      //   if(item.isCheck == true ){
      //     size += item.size
      //   }
      //   return size
      // })

      //只保留当前页选择
      this.yunpanfileList.filter(item=>{
        if(item.isCheck == true ){
          size += item.size
        }
        return size
      })

      return size
    },
    fileSize(){
      let size = 0
      this.fileList.filter( item=>{
        return size+=item.size
      })
      return  size
    },
    //获取所有需要上传文件的字节
    uploadfilesize(){
      let size = 0;

      this.fileList.forEach(item=>{
        item.files.filter(data=>{
          return  size += data.file.size
        })

      })
      return size
    },

  },
  watch:{
    uploadLoaded(newVal,oldVal){
      let speed = newVal - oldVal
      let upload_speed = Math.round((this.uploadSize + newVal) * 100 / this.fileSize)
      if(upload_speed >= 99){
        this.percentage = 99
      }else{
        this.percentage = upload_speed > 100?100:upload_speed
      }
      let t1 = new Date(); // 已上传文件大小时间
      let s = Math.round(
          speed / 1024
      );
      if (s > 1024) {
        this.speed = (s / 1024).toFixed(2) + "M/s";
      } else {
        this.speed = s < 0 ?1  + "kb/s":s+"kb/s";
      }
    },

    'setUpLinkObj.showPrice2'(newVal){
      console.log('newVal',newVal)
      if(newVal){
        clearTimeout(this.timer)
        this.$set(this.setUpLinkObj,'showPrice',true)
      }else{
        this.$set(this.setUpLinkObj,'showPrice',false)
      }
    },
    closePopup(newVal){
      if(!newVal){
        this.showTooltips = false
        this.continueDownLoadTipsState = true
      }
    },
  },
  created(){
    this.getshareRecordData()
    this.getNowDate()
    this.getHomeData()
    this.getYpyUploadInfo()
  },
  mounted() {

  },
  methods: {
    contentClick() {
      this.$Message.success('该文件您已经下载到本地设备，可到自己设置的浏览器下载路径中查看，云端只记录了您的下载记录，无法查看已下载到本地的文件。')
    },
    //过滤输入框
    limitInput(){
      this.setUpLinkObj.price =
          ("" + this.setUpLinkObj.price) // 第一步：转成字符串
              .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有
    },
    //获取首页参数
    getHomeData(){
      getHomeInfo().then(res=>{
        this.homeInfo = res.data
      })
    },
    //获取上传又拍云参数
    getYpyUploadInfo(){
      uploadBefore().then(res=>{
        this.uploadBefore = res.data
        //敏感关键字
        this.sensitiveKeywordList = res.data.violation_words_list
        this.uploadBefore.save_key =   res.data.upload_path + '/' + res.data.uuid_path +'/' + res.data.time_path +'/'

      })
    },
    //切换viewType
    handelViewType(index){
      if(index == 1){
        this.viewType = index
        this.pageIndex = 1
        this.pagingState = false
        this.recordList = []
        this.getshareRecordData()

      }
      if(index == 2){
        this.viewType = index
        this.pageIndex = 1
        this.pagingState = false
        this.getRecordsList = []
        this.getExpensesRecorData()
      }
    },
    //获取当前时间
    getNowDate () {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      this.time = year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
      // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    },
    //获取传输记录
    getshareRecordData(){
      let params = {
        order_by:this.sortVal.orderBy,
        filter:this.filterVal.filter,
        page:this.pageIndex,
        limit:50,
      }
      let arr = []
      let arr1 = []
      shareRecord(params).then(res=>{
        this.recordObj = res.data
        if(res.code == 1){
          if(res.data.list.length == 0) this.pagingState = true
          for(let item in this.recordObj.list){
            this.$set(this.recordObj.list[item],'isSelect',false)
            this.$set(this.recordObj.list[item],'isShowList',false)
            //更多操作...
            this.$set(this.recordObj.list[item],'isShowMore',false)
            //时间操作
            this.$set(this.recordObj.list[item],'isShowDate',false)
            //更多操作中的重新上传
            this.$set(this.recordObj.list[item],'isShowAgain',false)
            //链接设置
            this.$set(this.recordObj.list[item],'isShowSetUpLink',false)    //链接设置操作

            arr =  this.recordObj.list[item].files_list.split('|')
            this.recordObj.list[item].files_list = []
            for(let data in arr){
              arr1= arr[data].split(':')
              this.recordObj.list[item].files_list.push({
                name:arr1[0],
                size:arr1[1],
                id:arr1[2],
                type:arr1[3]
              })
            }
            this.recordList.push(this.recordObj.list[item])
          }
        }else{
          this.$Message.error(`${res.msg}`)
        }

      })
    },
    //获取取件记录
    getExpensesRecorData(){
      let params = {
        page:this.pageIndex,
        limit:50,
      }
      getExpensesRecordList(params).then(res=>{
        if(res.code == 1){
          if(res.data.list == 0) this.pagingState = true
          let arr = res.data.list
          for(let item in arr){
            let name = arr[item].share_title.substr(0,arr[item].share_title.lastIndexOf('等'))
            let number = arr[item].share_title.substr(arr[item].share_title.lastIndexOf('等'))
            this.$set(arr[item],'isSelect',false)
            this.$set(arr[item],'name',name)
            this.$set(arr[item],'number',number)
            this.getRecordsList.push(arr[item])
          }
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //取消选择
    handleCancelSelect(){
      this.recordList.forEach(item=>item.isSelect=false)
      this.isClickSelect=false;
    },
    //删除记录
    deleteCancelSelect(){
      this.showDeleteRecord = true
    },
    //点击全选
    handleClickAll(){
      if(this.isCheckAll){
        this.recordList.forEach(item=>item.isSelect=false)
      }else{
        this.recordList.forEach(item=>item.isSelect=true)
      }
    },
    //筛选
    handleClickFilter(id,type,filter){
      this.filterVal.id=id;
      this.filterVal.type=type;
      this.filterVal.filter = filter
      this.showFilter=false;
      this.recordList = []
      this.pageIndex = 1
      this.getshareRecordData()
    },
    handleFilterClose(){
      this.showFilter=false;
    },
    //排序
    handleClickSort(id,type,orderBy){
      this.sortVal.id=id;
      this.sortVal.type=type;
      this.sortVal.orderBy = orderBy
      this.recordList = []
      this.pageIndex = 1
      this.getshareRecordData()
      this.showSort=false;
    },
    handleSortClose(){
      this.showSort=false;
    },
    //选择到期时间
    handleClickExpireTime(id,type){
      let params = {
        id:id,
        expiry_day:type
      }
      editExpiryTime(params).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.pageIndex = 1
          this.pagingState = false
          this.recordList = []
          this.getshareRecordData()
        }else{
          this.$Message.error(`${res.msg}`)

        }
      })
    },
    //关闭到期时间选择
    handleCloseExpireTime(){
      this.showExpireTimeList=false;
    },
    //复制
    copySuccess(){
      this.$Message.success('复制成功')
    },
    copyError(){
      this.$Message.warning('复制失败')
    },
    // 打开上传文件弹窗
    again(id,key,index,data){
      //重新上传
      if(id == 1){
        this.recordList.forEach((item,t)=>{
          if(index == t){
            item.isShowAgain = !item.isShowAgain
            item.isShowSetUpLink = false
          }else{
            item.isShowAgain = index == t ? true:false
          }
        })
      }
      //下载全部
      if(id == 2){
        this.fileInfo = data
        for(let item in data.files_list){
          if(data.files_list[item].name == ''){
            return  this.$Message.error('文件不存在，无法下载')
          }
        }
        // this.showZipListType = 1
        // this.$store.commit('',this.fileInfo)
        // this.downloadShare()
        let filesList = this.fileInfo.files_list
        let arr = []
        for(let item in filesList){
          arr.push({
            id:Number(filesList[item].id),
            type:Number(filesList[item].type),
          })
        }
        this.downloadList = arr
        this.packZipTime = 1

        this.handelerDownload()

      }
      if(id == 3){
        this.fileInfo = data
        this.showDeleteTransfer = true
      }
      //设置链接
      if(id == 4){

        console.log('data',data)
        for(let item in data.files_list){
          if(data.files_list[item].name == ''){
            return  this.$Message.error('文件不存在，无法修改')
          }
        }

        this.recordList.forEach((item,t)=>{
          if(index == t){
            item.isShowSetUpLink = !item.isShowSetUpLink
            item.isShowAgain = false
            //每次展开重置弹窗状态
            if(item.isShowSetUpLink){
              this.$set(this.setUpLinkObj,'s1',false)
              this.$set(this.setUpLinkObj,'s2',false)
              this.$set(this.setUpLinkObj,'s3',false)
              this.$set(this.setUpLinkObj,'searchPermission',false)
              this.$set(this.setUpLinkObj,'price','')
              this.$set(this.setUpLinkObj,'showPrice',false)
              this.$set(this.setUpLinkObj,'showPrice1',true)
              this.$set(this.setUpLinkObj,'showPrice2',true)
              this.$set(this.setUpLinkObj,'isProvideFlow',false)
              this.$set(this.setUpLinkObj,'providePermissionVisible',false)
              this.$set(this.setUpLinkObj,'validPeriodVisible',false)
              this.$set(this.setUpLinkObj,'validPeriod',{})
              this.$set(this.setUpLinkObj,'validPeriodList',[])
              this.$set(this.setUpLinkObj,'providePermission',{id:1,val:'下载、转存及预览'})
              this.$set(this.setUpLinkObj,'flow',1)
              this.$set(this.setUpLinkObj,'flowTotal',0)
              this.$set(this.setUpLinkObj,'supplement', '')
              let params = {
                share_id : data.id
              }
              getShareInfo(params).then(res=>{
                console.log(res)
                if(res.code == 1){
                  let look_type;
                  const providePermission = res.data.share_info.look_type;
                  this.$set(this.setUpLinkObj,'validPeriodList',res.data.expiry_day_list)
                  if(providePermission == 1) {
                    look_type = {id:1,val:'下载转存及预览'}
                  }else if(providePermission == 2) {
                    look_type = {id:2,val:'仅下载或转存'}
                  }else{
                    look_type = {id:3,val:'仅预览'}
                  }
                  if(res.data.share_info.search_status == 1) {
                    this.$set(this.setUpLinkObj,'searchPermission',true)
                  }else{
                    this.$set(this.setUpLinkObj,'searchPermission',false)
                  }
                  this.$set(this.setUpLinkObj,'price',res.data.share_info.price)
                  this.$set(this.setUpLinkObj,'providePermission',look_type)
                  this.token = this.$store.state.token || localStorage.getItem('token')
                  let id  = this.token ? this.setUpLinkObj.validPeriodList[1] : this.setUpLinkObj.validPeriodList[0]
                  let val  = res.data.share_info.expiry_day
                  this.$set(this.setUpLinkObj.validPeriod,'id',id)
                  this.$set(this.setUpLinkObj,'size_total',res.data.share_info.size_total)
                  this.$set(this.setUpLinkObj,'free_flow_total',res.data.share_info.free_flow_total)
                  this.$set(this.setUpLinkObj.validPeriod,'val',val)
                  this.$set(this.setUpLinkObj,'supplement',res.data.share_info.remark)
                  this.setUpLinkObj.flowTotal = res.data.share_info.size_total

                }else{
                  this.$Message.error(`${res.msg}`)
                }
              })
            }
          }else{
            console.log('else')
            item.isShowSetUpLink = index == t ? true:false
          }
        })
      }
    },
    //下载
    handelerDownload(){
      let data = {
        files_list:this.downloadList
      }
      getSizeTotal(data).then(res=>{
        console.log('res',res)
        //选中文件的总大小
        this.fileAllSize = res.data.size_total
        this.fileSizeTotalInfo = res.data
        let resData = res.data
        let list = data.files_list
        this.freeDown = res.data.free_down
        //是否需要打包下载
        //文件数量超过1 需要打包
        if(list.length > 1){
          this.isZip = true
        }else{
          //判断单个文件的时候 是什么类型 2文件 1文件夹
          if(list[0].type == 2){
            this.isZip = false
          }else{
            this.isZip = true
          }
        }
        let time = Math.ceil(this.fileAllSize / 1670)
        if(time > 60 && this.isZip &&  this.continueDownLoadTipsState){
          this.showContinueDownLoadTips = true
          // this.showdownLoadTips = false
          this.packZipTime = Math.ceil(time / 60)
          return  false
        }
        //选中文件的总大小
        if(this.freeDown == 0){
          if(resData.size_total <= resData.visitor_download_max){
            // this.showZip = false
            this.showTooltips = false
            this.handelfiledownload()
          }
          //文件大于免流量下载最大限制 需要弹窗
          if(resData.size_total >= resData.visitor_download_max) {
            // this.showZip = true
            this.showTooltips = true
            this.f1 = true
            this.f2 = false
            this.f3 = false
          }
          //流量不够
          if(this.userInfo.flow_over_total < resData.size_total ){
            // this.showZip = true
            this.showTooltips = true
            this.f1 = false
            this.f2 = false
            this.f3 = true
          }
        }else{
          this.showTooltips = false
          this.handelfiledownload()
        }

      })
    },
    isWeChatPC() {
      var ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('micromessenger') != -1 && (ua.indexOf('windowswechat') != -1 || ua.indexOf('macwechat') != -1);
    },
    //确认下载
    handelfiledownload(){
      this.$store.state.closePopup = true
      if(this.isWeChatPC()) {
        this.$Message.error('当前浏览器不支持大文件分发，请使用其它浏览器下载')
        return false
      }
      this.$store.commit('handelDownLoadData',{
        totalSize:this.fileAllSize,//总大小
        downLoadList:this.downloadList,//数据
        type:'record',
        time:this.packZipTime,
        flowType:true,
        id:this.fileInfo.id,
        freeDown:this.freeDown,
        flowOverTotal:this.userInfo.flow_over_total,//用户流量
        visitorDownloadMax:this.fileSizeTotalInfo.visitor_download_max//免流量下载最大限制
      })
    },
    //获取随机数
    getrandom(){
      this.random = Math.random()
    },
    //上传
    uploadFile(file,type){
      let homeInfo = JSON.parse(localStorage.getItem('home'))
      if(homeInfo.open_share_status == 0){
        this.$Message.error('分享者已关闭上传功能')
        return  false
      }
      let _this = this
      let size = 0
      let state = false
      let files = file.target.files
      let isUpload = true
      //拆分数据 只获取file 对象
      let fileArr = []
      let folder = []
      for(let item in files){
        if(files[item].name && files[item].size){
          fileArr.push(files[item])
        }
      }

      console.log('arr',fileArr)
      fileArr.forEach(item=>{
        if(this.$filterKeyword(item.name,this.sensitiveKeywordList)){
          isUpload = false
          return  this.$Message.error('文件名包含敏感字')
        }
        let obj = {
          name:item.webkitRelativePath?item.webkitRelativePath.substring(0,item.webkitRelativePath.indexOf('/')):item.name,
          files:[],
          size:item.size,
          md5:'',
          suffix:item.webkitRelativePath?'folder':item.name.substring(item.name.lastIndexOf('.') + 1),
        }
        size += item.size
        obj.files.push({
          file:item,
          md5:'',
          name:item.name,
          size:item.size
        })
        folder.push(
            {
              file:item,
              md5:'',
              name:item.name,
              size:item.size
            }
        )
        for(let file in _this.fileList){
          if(_this.fileList[file].name == (type == 'file'?item.name:obj.name)){
            _this.fileList[file].files = type == 'file'? obj.files:folder
            _this.fileList[file].size = size
            state = true
            break
          }
        }
        if(!state){
          _this.fileList.push(obj)
        }
      })
      if((this.fileSize / 1000 / 1000) > this.uploadBefore.upload_size_max){
        this.showFileTransfer = false
        return this.$Message.error('超出最大上传')
      }
      file.target.value = ''
      if(!isUpload) return  false
      //文件上传
      this.showFileTransfer = true
      this.yunpanObj.type = 2
      this.showSendFile2 = false
      // isShowList  isClickSelect isShowMore
      for(let item in this.recordList){
        this.$set(this.recordList[item],'isShowList',false)
        this.$set(this.recordList[item],'isClickSelect',false)
        this.$set(this.recordList[item],'isShowMore',false)
        this.$set(this.recordList[item],'isShowAgain',false)
      }
      this.getYpyUploadInfo()
    },
    //删除文件
    deleteFile(index){
      this.fileList.splice(index,1)
      if(this.fileList.length == 0){
        this.showFileTransfer = false
      }
    },
    //滚动到底部时触发
    getYunpanPage(e){
      if ((e.srcElement.scrollTop + 1) + e.srcElement.clientHeight >= e.srcElement.scrollHeight && e.srcElement.scrollHeight != 0) {
        this.$nextTick(()=>{
          this.yunpanIndex +=1
          let prev_id =  localStorage.getItem('prev_id')
          console.log('prev_id',prev_id)
          this.opencloudDiskVisible({id:prev_id ,state:true},1)
        })

      }
    },
    //云盘勾选添加数据
    pushIsCheck(data){
      let arr = this.yunpanList
      this.$nextTick(()=>{
        if(data.isCheck){
          if(data.type == 1){
            data.suffix = 'folder'
          }
          this.yunpanObj.type = 1
          this.yunpanList.push(data)
          this.yunpanObj.files = this.yunpanList

        }else{
          arr.forEach((item,index)=>{
            if(data.id == item.id){
              arr.splice(index,1)
            }
          })
          this.yunpanList = arr
        }
        if(this.yunpanfileList.every(item=>item.isCheck === true)){
          this.yunpanAllCheck = true
        }else{
          this.yunpanAllCheck = false
        }
      })
    },
    //云盘点击全选
    handleyunpanClickAll(){
      this.$nextTick(()=>{
        this.yunpanfileList.forEach((item,index)=>{
          if(this.yunpanAllCheck){
            item.isCheck = true
            this.yunpanList.push(item)
            if(item.type == 1){
              item.suffix = 'folder'
            }
          }else{
            for (let data in this.yunpanList){
              if(this.yunpanList[data].id == item.id){
                this.yunpanList.splice(data ,1)
                item.isCheck = false
              }
            }

          }
        })
        this.yunpanList = Array.from(new Set(this.yunpanList))
        this.yunpanObj.files = this.yunpanList
      })
    },
    //打开云盘弹窗
    opencloudDiskVisible(data,type,index){
      this.cloudDiskVisible = true
      //数据等于空时 就不执行接口了
      if(this.yunpanState) return false

      for(let item in this.recordObj.list){
        this.recordObj.list[item].isShowMore = false
        this.recordObj.list[item].isShowAgain = false
      }
      let params = {
        prev_id:data?data.id:'',
        operate:1,
        page:this.yunpanIndex,
        limit:50,
      }
      console.log('params',params)
      localStorage.setItem('prev_id',data?data.id:'')
      let arr  = []
      if(type == 1){
        getPanList(params).then(res=>{
          if(data.state){
            arr = res.data.list
            if(res.data.list.length == 0) {
              this.yunpanState = true
            }else{
              this.yunpanState = false
            }
            for (let item in arr){
              this.$set(arr[item],'isCheck',false)
              this.yunpanfileList.push(arr[item])
            }
          }else{
            this.yunpanfileList = res.data.list
            for(let item in this.yunpanfileList){
              this.$set(this.yunpanfileList[item],'isCheck',false)
            }
            this.yunpanIndex = 1
            this.yunpanAllCheck = false
          }
          // if(this.yunpanfileList.every(item=> item.isCheck == true)){
          //   this.yunpanAllCheck = true
          // }
          // for(let item in this.yunpanList){
          //   for(let data in this.yunpanfileList){
          //     if(this.yunpanList[item].id == this.yunpanfileList[data].id){
          //       this.yunpanfileList[data].isCheck = this.yunpanList[item].isCheck
          //     }
          //   }
          // }

          if(data.state == false || data.state == null || data.state == undefined){
            if(res.data.prev_id == null){
              this.yunpanTitle = []
              this.yunpanIndex = 1
              this.yunpanTitle.push({
                title:'全部',
                id:''
              })
            }else{
              let state = false
              for(let item in this.yunpanTitle){
                if(this.yunpanTitle[item].id == data.id ){
                  state = true
                  this.yunpanTitle.splice(index+1,this.yunpanTitle.length - item)
                }
              }
              if(!state){
                this.yunpanTitle.push({
                  id:data.id,
                  title:data.name
                })
              }
            }
          }
        })
      }


    },
    //从我的云盘中提交数据
    submityunpanData(){

      //关闭上传文件弹窗
      // this.showSendFile2 = false
      // this.fileList = this.yunpanObj.files
      if(this.yunpanfileList.length == 0) return this.$Message.error('请选择文件')
      let arr = []
      for(let item in this.yunpanfileList){
        if(this.yunpanfileList[item].isCheck){
          arr.push({
            name:this.yunpanfileList[item].name,
            size:this.yunpanfileList[item].size,
            suffix:this.yunpanfileList[item].type == 1 ? 'folder' :this.yunpanfileList[item].suffix,
            id:this.yunpanfileList[item].id,
            type:this.yunpanfileList[item].type,
          })
        }
      }
      console.log('arr',arr)

      let data = {
        files_list : arr
      }
      getSizeTotal(data).then(res=>{
        if(res.code == 1){
          this.fileAllSize = res.data.size_total
          this.fileList = arr
          this.showFileTransfer = true
          //提交的时候关闭 云盘弹窗
          this.cloudDiskVisible = false
        }else{
          this.$Message.error(`${res.msg}`)
          this.cloudDiskVisible = false
        }
      })

      console.log('this.fileList1',this.fileList)
    },
    //最后上传
    async  submitUpload(){
      let uploadFileList = []
      let promiseArr = []
      let index = 0
      this.percentage = 0
      if(!this.isAgree){
        return this.$Message.error('请勾选用户协议')
      }
      //云盘上传
      if(this.yunpanObj.type == 1){
        for(let item in this.fileList){
          uploadFileList.push({
            type:this.fileList[item].type,
            id:this.fileList[item].id,
          })
        }

        let data1 ={
          share_id:this.share_id,
          prev_id:null,
          title:this.fileList[0].name,
          files_list:uploadFileList
        }
        this.showTransfer = true
        this.showFileTransfer = false
        reUploadShareByDisk(data1).then(res=>{
          console.log('res',res)
          this.speed = '传输完成'
          this.percentage = 100
          this.pageIndex = 1
          this.pagingState = false
          this.recordList = []
          this.getshareRecordData()
          setTimeout(()=>{
            this.showTransfer = false
          },3000)
        })
      }else{
        console.log('this.fileList[item]',this.fileList)

        for(let item in this.fileList){
          for(let file in this.fileList[item].files){
            uploadFileList.push({
              name:this.fileList[item].files[file].file.webkitRelativePath?this.fileList[item].files[file].file.webkitRelativePath:this.fileList[item].files[file].file.name,
              size:this.fileList[item].files[file].file.size
            })
          }
        }
        console.log('上传数据',uploadFileList)
        let t0 =   new Date(); // 文件开始上传时间
        //文件上传
        let data = {
          share_id:this.share_id,
          time_path:this.uploadBefore.time_path,
          uuid_path:this.uploadBefore.uuid_path,
          upload_path:this.uploadBefore.upload_path,
          title:this.fileList[0].name,
          files_list:uploadFileList,
        }
        let that = this
        let files = ''
          this.showTransfer = true
          this.showFileTransfer = false
          for (let item in this.fileList) {
            for (let file in this.fileList[item].files) {
              let obj = this.$getSign(
                  '',
                  this.uploadBefore.save_key,
                  this.fileList[item].files[file].md5,
                  this.fileList[item].files[file].file.size,
                  this.fileList[item].files[file].file.webkitRelativePath?this.fileList[item].files[file].file.webkitRelativePath:this.fileList[item].files[file].file.name,
                  this.uploadBefore.bucket_path,
                  this.uploadBefore.upload_path,
                  this.uploadBefore.uuid_path,
                  this.uploadBefore.time_path
              )
              console.log('files',this.fileList[item].files[file])
              promiseArr[index] = await new Promise( async (resolve)=>{
                if(this.fileList[item].files[file].file.size < (50 * 1024 * 1024)){
                  let directlyFile =  await this.directlyFile(this.fileList[item].files[file].file)
                  resolve(directlyFile)
                }else{
                  let headers =  await this.initializeFile(this.fileList[item].files[file].file,obj)
                  let success = await this.handelchunkFile(headers,this.fileList[item].files[file].file)
                  resolve(success)
                }
                index++
              })

            }
          }
        Promise.all(promiseArr).then(res=>{
          reUploadShareByFiles(data).then( res => {
            if(res.code == 1){
              this.percentage = 100
              setTimeout(()=>{
                this.showTransfer = false
              },3000)
              this.pageIndex = 1
              this.pagingState = false
              this.recordList = []
              this.getshareRecordData()
              this.$Message.success(`${res.msg}`)
            }else{
              setTimeout(()=>{
                this.showTransfer = false
              },3000)
              this.pageIndex = 1
              this.pagingState = false
              this.recordList = []
              this.getshareRecordData()
              this.$Message.error(`${res.msg}`)
            }
          })
        })

      }
    },
   //直接上传 如果文件小于1m 直接上传 不需要分片
   async directlyFile(file){
      let that = this
      let formdata = new FormData
      formdata.append('file',file)
     let name  = file.webkitRelativePath?file.webkitRelativePath:file.name

     return await new Promise(  resolve => {

        let obj = that.$getSign(
            '',
            that.uploadBefore.save_key,
            '',
            file.size,
            name,
            that.uploadBefore.bucket_path,
            that.uploadBefore.upload_path,
            that.uploadBefore.uuid_path,
            that.uploadBefore.time_path
        )
        axios.post(`https://v0.api.upyun.com${  '/'+ that.uploadBefore.bucket_path + that.uploadBefore.upload_path +'/'+that.uploadBefore.uuid_path+'/'+that.uploadBefore.time_path + '/' }${name}`, file,{
          onUploadProgress:(progressEvent)=>{
            this.uploadLoaded = progressEvent.loaded
          },
          headers: {
            'Authorization':obj.sign,
            "x-date":obj.date,
          }
        }).then(res => {
          this.uploadSize += file.size
          resolve(res)
        }).catch(error=>{
          that.showTransfer = false
          that.$Message.error('上传失败')
        })
      })
   },
    //初始化文件分块任务
   async initializeFile(file,obj){
      let that = this
     let name  = file.webkitRelativePath?file.webkitRelativePath:file.name

     return await new Promise(  resolve => {
        axios.post(`https://v0.api.upyun.com${  '/'+ that.uploadBefore.bucket_path + that.uploadBefore.upload_path +'/'+that.uploadBefore.uuid_path+'/'+that.uploadBefore.time_path + '/' }${name}`, {},{
          headers: {
            'Authorization':obj.sign,
            "x-date":obj.date,
            'X-Upyun-Multi-Stage':'initiate',
            'X-Upyun-Multi-Length':file.size,
            // 'X-Upyun-Multi-Part-Size': 10485760,
            'X-Upyun-Multi-Part-Size': 10 * 1024 * 1024
          }
        }).then(res => {
          let headers = res.headers

          this.shardSize = headers['x-upyun-next-part-size']
          resolve(headers)
        }).catch(error=>{
          that.showTransfer = false
          that.$Message.error('上传失败')
        })
      })
    },
    //分块上传任务
   async handelchunkFile(data,file){
      let chunkSize =  data['x-upyun-next-part-size'] //总大小
      let item;//下标
      let shardSize  = this.shardSize //单片大小
      let filedata;//数据
      let that = this
      var headers;
      var start;
      let total;
      var end;
     let name  = file.webkitRelativePath?file.webkitRelativePath:file.name

     return await new Promise( async  resolve => {
        async function endupload(data,file){
          let obj = that.$getSign(
              '',
              that.uploadBefore.save_key,
              '',
              file.size,
              name,
              that.uploadBefore.bucket_path,
              that.uploadBefore.upload_path,
              that.uploadBefore.uuid_path,
              that.uploadBefore.time_path
          )
        await  axios.post(`https://v0.api.upyun.com${  '/'+ that.uploadBefore.bucket_path + that.uploadBefore.upload_path +'/'+that.uploadBefore.uuid_path+'/'+that.uploadBefore.time_path + '/' }${name}`, {},{
            headers: {
              'Authorization':obj.sign,
              "x-date":obj.date,
              'X-Upyun-Multi-Stage':'complete',
              'X-Upyun-Multi-Uuid': data['x-upyun-multi-uuid'],
              'X-Upyun-Part-Id':data['x-upyun-next-part-id'],
              'X-Upyun-Multi-Part-Size': data['x-upyun-next-part-size'],
            }
          }).then(res => {
            headers = res.headers
            console.log('上传完成res',res)
            that.uploadSize += total
            resolve(res)
          }).catch(error=>{
          that.showTransfer = false
          that.$Message.error('上传失败')
        })
        }
        async function upload(headers,file){
          item = headers['x-upyun-next-part-id']
          console.log('headers',headers,'item',item)
          //处理最后一片 切片
          if ((headers['x-upyun-remain-size'] * 1) < (that.shardSize) * 1) {
            start = item * (that.shardSize * 1);
            end = file.size;
            filedata =  file.slice(start,end)
          } else {
            start = item * (that.shardSize * 1);
            end = (start * 1)  + (that.shardSize * 1);
            filedata =  file.slice(start,end)
          }
          let obj = that.$getSign(
              '',
              that.uploadBefore.save_key,
              '',
              file.size,
              name,
              that.uploadBefore.bucket_path,
              that.uploadBefore.upload_path,
              that.uploadBefore.uuid_path,
              that.uploadBefore.time_path
          )
         await axios.post(`https://v0.api.upyun.com${  '/'+ that.uploadBefore.bucket_path + that.uploadBefore.upload_path +'/'+that.uploadBefore.uuid_path+'/'+that.uploadBefore.time_path + '/' }${name}`, filedata,{
           onUploadProgress:(progressEvent)=>{
             that.uploadLoaded = progressEvent.loaded
             if(progressEvent.loaded == progressEvent.total){
               total = progressEvent.total
               // that.uploadSize +=  JSON.parse(JSON.stringify(progressEvent.total))
             }
          },
           headers: {
              'Authorization':obj.sign,
              "x-date":obj.date,
              'X-Upyun-Multi-Stage':'upload',
              'X-Upyun-Multi-Uuid': headers['x-upyun-multi-uuid'],
              'X-Upyun-Part-Id':headers['x-upyun-next-part-id'],
              'X-Upyun-Multi-Part-Size': headers['x-upyun-next-part-size'],
           }
          }).then(  res => {
            headers = res.headers
            if(headers['x-upyun-next-part-id']  == -1 && headers['x-upyun-next-part-size'] == -1 ){
                endupload(res.headers,file)
            }else{
              that.uploadSize +=  (shardSize * 1)
               upload(res.headers,file)
            }
          }).catch(error=>{
           that.showTransfer = false
           that.$Message.error('上传失败')
         })
        }
        upload(data,file)
      })
    },
    //销毁任务
    handeldeleteShare(type){
      let arr = []
      console.log('type',type)
      if(type == 1){
        arr.push({
          id:this.fileInfo.id
        })
      }else{
        this.recordList.forEach(item=>{
          if(item.isSelect == true){
            arr.push({
              id:item.id
            })
          }
        })
      }

      let data = {
        files_list:arr
      }
      deleteShare(data).then(res=>{
        console.log('res',res)
        if(res.code == 1){
          this.showDeleteTransfer = false
          this.showDeleteRecord = false
          this.pageIndex = 1
          this.pagingState = false
          this.recordList = []
          this.getshareRecordData()
          this.$Message.success(`${res.msg}`)
        }else{
          this.showDeleteTransfer = false
          this.showDeleteRecord = false
          this.pageIndex = 1
          this.pagingState = false
          this.recordList = []
          this.getshareRecordData()
          this.$Message.error(`${res.msg}`)
        }

      })
    },
    //重新下载
    downloadShare(){
      let data = {
        id:this.fileInfo.id
      }
      myShareDownload(data).then(res=>{
        console.log('res',res)
        if(res.code  == 1){
          this.packZipList.push({
            file_name:res.data.file_name,
            task_id:res.data.task_id,
            states:0,
          })
          this.getdownloadUrl(res.data.task_id)
        }else{
          this.$Message.error(`${res.msg}`)
        }

      })
    },
    getdownloadUrl(task_id) {
      let params = {}
      let closeInterVal =  setInterval(()=>{
        for(let item in this.packZipList){
          if(this.packZipList[item].states == 0){
            params.task_id = this.packZipList[item].task_id
            inquireTask(params).then(res=>{
              console.log('res',res)
              if(res.code == 1){
                this.$Message.success(`${res.msg}`)
                this.packZipList[item].states = 1
                this.packZipList[item].url = res.data.url
                var anchor = document.createElement('a');
                anchor.href = this.packZipList[item].url;
                anchor.download = this.packZipList[item].url;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                // window.open(`${this.packZipList[item].url}`, '_blank')
                clearInterval(closeInterVal)
              }
            })
          }

        }
      },2000)

    },
    downloadZip(url){
      // window.open(`${url}`, '_blank')
      var anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = url;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
    },
    toAgree(data){
      console.log('data',data)
      this.$router.push({
        path:'/advice',
        name:'Advice',
        params:{
          id:data.id
        }
      })
    },
    //跳转商城
    toShop(){
      this.showTooltips = false
      this.$router.push({
        path:'/shop',
        name:'Shop',
        params:{
          index:3
        }
      })
    },
    //页面滚动条触底事件
    scrollEvent(e,index){
      if ((e.srcElement.scrollTop  + 1) + e.srcElement.clientHeight >= e.srcElement.scrollHeight && e.srcElement.scrollHeight != 0 && !this.pagingState) {
        this.$nextTick(()=>{
          this.pageIndex++
          if(index == 1){
            this.getshareRecordData()
          }else{
            this.getExpensesRecorData()
          }
        })

      }
    },
    //三个点弹窗操作
    handelIsShowMore(item,index){
      for(let data in this.recordList){
        if(item.id == this.recordList[data].id){
          this.recordList[data].isShowMore = !this.recordList[data].isShowMore
        }else{
          this.$set(this.recordList[data],'isShowMore',false)
          this.$set(this.recordList[data],'isShowSetUpLink',false)
        }
      }
    },
    //定价
    handelPrice(item1,item){
        //判断是不是会员 1是会员 0不是会员
        if(this.userInfo.vip_status == 1){
          // this.showPrice = true
          this.setUpLinkObj.showPrice1 = false
        }else{
          this.setUpLinkObj.showPrice = !this.setUpLinkObj.showPrice
          this.timer = setTimeout(()=>{
            this.setUpLinkObj.showPrice = false
          },4000)
          if(this.setUpLinkObj.showPrice) return   this.$Message.error('该功能只对会员开放')

        }
    },
    //流量--
    reduce(){
      if(this.setUpLinkObj.flow <= 1){
        this.setUpLinkObj.isProvideFlow = false
        return false;
      }else{
        if(this.setUpLinkObj.visitor_upload == 1){
          this.$Message.error('请登录后使用')
        }else{
          if(this.userInfo.vip_status == 1){
            this.setUpLinkObj.isProvideFlow = true
            this.setUpLinkObj.flow--
          }else{
            this.setUpLinkObj.showPrice = true
            this.$Message.error('该功能只对会员开放')
            this.setUpLinkObj.flow = '1'
            setTimeout(()=>{
              this.setUpLinkObj.showPrice = false
            },2000)
          }
        }
      }
    },
    //流量++
    upReduce(){
      let num = Math.floor((parseInt(this.uploadBefore.member_flow_over) /  this.setUpLinkObj.flowTotal))
      if(this.setUpLinkObj.flow <= num){
        if(this.uploadBefore.visitor_upload == 1){
          this.$Message.error('请登录后使用')
        }else{
          if(this.userInfo.vip_status == 1){
            this.setUpLinkObj.isProvideFlow = true
            this.setUpLinkObj.flow++
          }else{
            this.setUpLinkObj.showPrice = true
            this.$Message.error('该功能只对会员开放')
            this.flow = '1'
            setTimeout(()=>{
              this.setUpLinkObj.showPrice = false
            },2000)
          }
        }
      }else{
        this.$Message.error('流量余额不足，请先充值')
      }
    },
    //提供流量
    handelProvideFlow(e){
      // let num = Math.floor((parseInt(this.uploadBefore.member_flow_over) /  this.setUpLinkObj.flowTotal))
      // if(num < 0) {
      //   this.$Message.error('流量余额不足，请先充值')
      //   this.setUpLinkObj.flow = '1'
      // } 
      if(this.uploadBefore.visitor_upload == 1){
        this.$Message.error('请登录后使用')
      }else{
        //判断是不是会员 1是会员 0不是会员
        if(this.userInfo.vip_status == 1){
          // this.showPrice = true
          this.setUpLinkObj.isProvideFlow = true
          // this.flow = e.target.value.replace(/[^\d]/g, "");
          const filteredValue = e.target.value.replace(/[^\d]/g, "");
          this.setUpLinkObj.flow = filteredValue;
        }else{
          this.setUpLinkObj.showPrice = true
          this.$Message.error('该功能只对会员开放')
          this.setUpLinkObj.flow = '1'
          setTimeout(()=>{
            this.setUpLinkObj.showPrice = false
          },2000)
        }
      }
    },
    //提交设置链接
    submitSetUpLink(item){
      let params = {
        share_id:item.id,
        search_status:this.setUpLinkObj.searchPermission?1:0,
        need_pay:this.setUpLinkObj.price?1:0,
        price:this.setUpLinkObj.price,
        look_type:this.setUpLinkObj.providePermission.id,
        free_num:this.setUpLinkObj.isProvideFlow?this.setUpLinkObj.flow:0,
        expiry_day:this.setUpLinkObj.validPeriod.val,
        remark:this.setUpLinkObj.supplement
      }
      console.log(this.setUpLinkObj.isProvideFlow)
      if(this.uploadBefore.member_flow_over < this.setUpLinkObj.flowTotal * this.setUpLinkObj.flow && this.setUpLinkObj.isProvideFlow) {
          this.$Message.error('流量余额不足，请先充值')
          return false
        }
      editShare(params).then(res=>{
        if(res.code == 1){
          this.pageIndex = 1
          this.pagingState = false
          item.isShowSetUpLink = false
          this.recordList = []
          this.getshareRecordData()
          this.$Message.success(`${res.msg}`)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    openDropdown(item){
      if(!item.isShowSetUpLink){
        item.isShowList = !item.isShowList
      }
    },
    //允许他人
    choiceDropdown(id,val){
      this.$set(this.setUpLinkObj.providePermission,'id',id)
      this.$set(this.setUpLinkObj.providePermission,'val',val)
      this.$set(this.setUpLinkObj,'providePermissionVisible',false)
    },
    //有效期
    choiceTimeDropdown(id,val){
      this.$set(this.setUpLinkObj.validPeriod,'id',id)
      this.$set(this.setUpLinkObj.validPeriod,'val',val)
      this.$set(this.setUpLinkObj,'validPeriodVisible',false)

    },
  },


}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/account/transferRecord.less';
</style>